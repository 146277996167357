import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0564bc61"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav nav-treeview" }

export function render(_ctx, _cache) {
  const _component_svg_icon = _resolveComponent("svg-icon")
  const _component_app_menu_item = _resolveComponent("app-menu-item")

  return (_ctx.menuItem.isView)
    ? (_openBlock(), _createElementBlock("li", {
        key: 0,
        class: _normalizeClass(["nav-item", {'menu-open': _ctx.isMenuExtended}])
      }, [
        _createElementVNode("a", {
          class: _normalizeClass(["nav-link", {'active': _ctx.isMainActive || _ctx.isOneOfChildrenActive}]),
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.handleMainMenuAction && _ctx.handleMainMenuAction(...args)))
        }, [
          _createVNode(_component_svg_icon, {
            class: "nav-icon",
            name: _ctx.getIcon
          }, null, 8, ["name"]),
          _createElementVNode("p", null, _toDisplayString(_ctx.menuItem.title), 1),
          (_ctx.isExpandable)
            ? (_openBlock(), _createBlock(_component_svg_icon, {
                key: 0,
                class: "right",
                name: "chevron-back"
              }))
            : _createCommentVNode("", true)
        ], 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItem.children, (item) => {
          return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
            _createVNode(_component_app_menu_item, { menuItem: item }, null, 8, ["menuItem"])
          ]))
        }), 256))
      ], 2))
    : _createCommentVNode("", true)
}