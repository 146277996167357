import { Options, Vue } from "vue-class-component";
import MenuItem from "./menu-item/menu-item.vue";

@Options({
    name: "app-menu-sidebar",
    components: {
        "app-menu-item": MenuItem,
    },
})
export default class MenuSidebar extends Vue {
    get userInfo() {
        return this.$store.getters["auth/userInfo"];
    }
    get menu() {
        return this.$store.getters["router/menu"];
    }
    get sidebarSkin() {
        return this.$store.getters["ui/darkMenuSelected"] ? "sidebar-dark-default" : "sidebar-light-default";
    }
    get themeSkin() {
        return this.$store.getters["ui/themeSkin"];
    }
    get siteTitle() {
        return this.$store.getters["ui/siteTitle"];
    }
}
