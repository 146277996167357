import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f546bd70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navbar-nav" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "navbar-nav" }
const _hoisted_4 = {
  class: "nav-item",
  id: "controlSidebarButton"
}

export function render(_ctx, _cache) {
  const _component_svg_icon = _resolveComponent("svg-icon")
  const _component_tabs_views = _resolveComponent("tabs-views")
  const _component_messages_dropdown = _resolveComponent("messages-dropdown")
  const _component_notifications_dropdown = _resolveComponent("notifications-dropdown")
  const _component_user_dropdown = _resolveComponent("user-dropdown")

  return (_openBlock(), _createElementBlock("nav", {
    id: "main-header",
    class: _normalizeClass(["main-header navbar navbar-expand", _ctx.navbarVariant])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("button", {
          class: "nav-link",
          role: "button",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onToggleMenuSidebar && _ctx.onToggleMenuSidebar(...args)))
        }, [
          _createVNode(_component_svg_icon, { name: "reorder-four" })
        ])
      ])
    ]),
    _createVNode(_component_tabs_views),
    _createElementVNode("ul", _hoisted_3, [
      false
        ? (_openBlock(), _createBlock(_component_messages_dropdown, { key: 0 }))
        : _createCommentVNode("", true),
      false
        ? (_openBlock(), _createBlock(_component_notifications_dropdown, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_component_user_dropdown),
      _createElementVNode("li", _hoisted_4, [
        _createElementVNode("button", {
          class: "nav-link",
          onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.onToggleControlSidebar && _ctx.onToggleControlSidebar(...args)))
        }, [
          _createVNode(_component_svg_icon, { name: "bookmark" })
        ])
      ])
    ])
  ], 2))
}