import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6faad458"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-wrapper" }

export function render(_ctx, _cache) {
  const _component_app_header = _resolveComponent("app-header")
  const _component_menu_sidebar = _resolveComponent("menu-sidebar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_app_footer = _resolveComponent("app-footer")
  const _component_control_sidebar = _resolveComponent("control-sidebar")

  return (_openBlock(), _createElementBlock("div", {
    id: "wrapper",
    class: _normalizeClass(["wrapper", _ctx.themeSkin])
  }, [
    _createVNode(_component_app_header),
    _createVNode(_component_menu_sidebar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, {
            name: "fade",
            appear: "",
            "enter-active-class": "in animate__animated animate__fadeIn",
            "leave-active-class": "out animate__animated animate__fadeOut"
          }, {
            default: _withCtx(() => [
              (route.meta.keepAlive)
                ? (_openBlock(), _createBlock(_KeepAlive, { key: 0 }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                      key: route.name || route.path
                    }))
                  ], 1024))
                : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 1 }))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_app_footer),
    _createVNode(_component_control_sidebar),
    (_ctx.screenSize === 'xs' && _ctx.menuSidebarCollapsed)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "sidebar-overlay",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onToggleMenuSidebar && _ctx.onToggleMenuSidebar(...args)))
        }))
      : _createCommentVNode("", true)
  ], 2))
}