import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1938ba86"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "nav-item dropdown",
  ref: "dropdownNav"
}

export function render(_ctx, _cache) {
  const _component_app_dropdown_nav_menu = _resolveComponent("app-dropdown-nav-menu")

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    (_ctx.mode==='icon')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
        }, [
          _renderSlot(_ctx.$slots, "dropdown-nav-view", {}, undefined, true)
        ]))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "nav-link",
          onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
        }, [
          _renderSlot(_ctx.$slots, "dropdown-nav-view", {}, undefined, true)
        ])),
    (_ctx.isOpen)
      ? (_openBlock(), _createBlock(_component_app_dropdown_nav_menu, {
          key: 2,
          size: _ctx.size,
          side: _ctx.side
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "dropdown-nav-menu", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["size", "side"]))
      : _createCommentVNode("", true)
  ], 512))
}