import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-menu-item",
    props: {
        menuItem: Object,
        icon: String,
        type: String,
        placeholder: String,
        autocomplete: String,
    },
})
export default class MenuItem extends Vue {
    private menuItem: any;
    private isMenuExtended = false; //是否已展开
    private isExpandable = false; //是否可以展开
    private isMainActive = false; //主菜单是否激活
    private isOneOfChildrenActive = false; //下级菜单是否激活
    private isActive = false; //是否激活

    public mounted(): void {
        this.isExpandable = this.menuItem && this.menuItem.children && this.menuItem.children.length > 0;
        this.calculateIsActive(this.$router.currentRoute.value.path);
        this.$router.afterEach((to: any) => {
            this.calculateIsActive(to.path);
        });
    }

    public handleMainMenuAction() {
        if (this.isExpandable) {
            this.toggleMenu();
            return;
        }
        this.$router.replace(this.menuItem.path);
    }

    public toggleMenu() {
        this.isMenuExtended = !this.isMenuExtended;
    }

    public calculateIsActive(url: string) {
        this.isActive = false;
        this.isMainActive = false;
        this.isOneOfChildrenActive = false;
        this.isMenuExtended = false;
        if (this.isExpandable) {
            if (this.chkChildrenActive(this.menuItem, url)) {
                this.isOneOfChildrenActive = true;
                this.isMenuExtended = true;
            }
        } else if (this.menuItem.path === url) {
            this.isMainActive = true;
        }
        if (!this.isMainActive && !this.isOneOfChildrenActive) {
            this.isMenuExtended = false;
        }
    }

    public chkChildrenActive(item: any, url: string): boolean {
        if (item.path === url) return true;
        if (item.children) {
            item.children.forEach((children: any) => {
                if (children.path === url) {
                    this.isActive = true;
                    return;
                } else {
                    this.isActive = this.chkChildrenActive(children, url);
                    if (this.isActive) return;
                }
            });
        }
        return this.isActive;
    }

    get getIcon() {
        if (this.menuItem && this.menuItem.icon) return this.menuItem.icon;
        if (this.isExpandable) {
            return "ellipse";
        } else {
            return "ellipse-outline";
        }
    }
}
