import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-dropdown-nav-menu",
    props: {
        size: {
            type: String,
            default: () => "md",
        },
        side: {
            type: String,
            default: () => "right",
        },
    },
})
export default class DropdownNavMenu extends Vue {}
