import { Options, Vue } from "vue-class-component";
import Header from "./header/header.vue";
import MenuSidebar from "./menu-sidebar/menu-sidebar.vue";
import ControlSidebar from "./control-sidebar/control-sidebar.vue";
import Footer from "./footer/footer.vue";

@Options({
    components: {
        "app-header": Header,
        "menu-sidebar": MenuSidebar,
        "control-sidebar": ControlSidebar,
        "app-footer": Footer,
    },
    watch: {
        watchLayoutChanges: () => ({}),
    },
})
export default class Layout extends Vue {
    private appElement: HTMLElement | null = null;

    public async mounted(): Promise<void> {
        this.appElement = document.getElementById("app") as HTMLElement;
        this.appElement.classList.add("sidebar-mini");
        this.appElement.classList.add("layout-fixed");
    }

    public unmounted(): void {
        this.appElement.classList.remove("sidebar-mini");
        this.appElement.classList.remove("layout-fixed");
    }

    get watchLayoutChanges() {
        if (!this.appElement) {
            return;
        }

        //字体大小
        const docEl = document.documentElement;
        docEl.style.fontSize = "1rem";
        // const clientWidth = docEl.clientWidth;
        // let fontSize = 20 * (clientWidth / 375);
        // fontSize = fontSize > 16 ? 16 : fontSize;
        // docEl.style.fontSize = fontSize + "px";

        if (this.$store.getters["ui/screenSize"] !== this.windowSize) this.$store.dispatch("ui/setWindowSize", this.windowSize);

        this.appElement.classList.remove("dark-mode");
        this.appElement.classList.remove("sidebar-closed");
        this.appElement.classList.remove("sidebar-collapse");
        this.appElement.classList.remove("sidebar-open");
        this.appElement.classList.remove("control-sidebar-slide-open");

        if (this.darkModeSelected) {
            this.appElement.classList.add("dark-mode");
        }

        if (!this.controlSidebarCollapsed) {
            this.appElement.classList.add("control-sidebar-slide-open");
        }

        if (this.screenSize === "lg") {
            this.appElement.classList.add("vmSize-lg");
            this.appElement.classList.remove("vmSize-md");
            this.appElement.classList.remove("vmSize-sm");
            this.appElement.classList.remove("vmSize-xs");
        } else if (this.screenSize === "md") {
            this.appElement.classList.remove("vmSize-lg");
            this.appElement.classList.add("vmSize-md");
            this.appElement.classList.remove("vmSize-sm");
            this.appElement.classList.remove("vmSize-xs");
        } else if (this.screenSize === "sm") {
            this.appElement.classList.remove("vmSize-lg");
            this.appElement.classList.remove("vmSize-md");
            this.appElement.classList.add("vmSize-sm");
            this.appElement.classList.remove("vmSize-xs");
        } else if (this.screenSize === "xs") {
            this.appElement.classList.remove("vmSize-lg");
            this.appElement.classList.remove("vmSize-md");
            this.appElement.classList.remove("vmSize-sm");
            this.appElement.classList.add("vmSize-xs");
        }

        if (!this.menuAndHeaderCollapsed) {
            if (this.menuSidebarCollapsed && this.screenSize === "lg") {
                this.appElement.classList.add("sidebar-collapse");
            } else if (this.menuSidebarCollapsed && this.screenSize === "xs") {
                this.appElement.classList.add("sidebar-open");
            } else if (!this.menuSidebarCollapsed && this.screenSize !== "lg") {
                this.appElement.classList.add("sidebar-closed");
                this.appElement.classList.add("sidebar-collapse");
            }
        } else {
            this.appElement.classList.add("sidebar-closed");
        }
        return this.appElement.classList.value;
    }

    get windowSize() {
        return this.$tools.calculateWindowSize(this.$windowWidth);
    }

    get darkModeSelected() {
        return this.$store.getters["ui/darkModeSelected"];
    }

    get menuSidebarCollapsed() {
        return this.$store.getters["ui/menuSidebarCollapsed"];
    }

    get controlSidebarCollapsed() {
        return this.$store.getters["ui/controlSidebarCollapsed"];
    }

    get menuAndHeaderCollapsed() {
        return this.$store.getters["ui/menuAndHeaderCollapsed"];
    }

    get screenSize() {
        return this.$store.getters["ui/screenSize"];
    }

    public onToggleMenuSidebar(): void {
        this.$store.dispatch("ui/toggleMenuSidebar");
    }

    get themeSkin() {
        return this.$store.getters["ui/themeSkin"];
    }
}
