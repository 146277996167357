import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-614b36d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navbar-nav navbar-tabs-views" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = {
  id: "navbar-tabs-list",
  class: "nav-sidebar overflow-hidden navbar-tabs-list"
}
const _hoisted_4 = {
  id: "navbar-tabs-list-item",
  class: "nav-themes",
  ref: "navbarTabsListItem"
}
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = {
  class: "nav-link",
  role: "button"
}
const _hoisted_8 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_svg_icon = _resolveComponent("svg-icon")
  const _component_router_link = _resolveComponent("router-link")
  const _component_app_dropdown_nav = _resolveComponent("app-dropdown-nav")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["navbar-nav navbar-tabs", _ctx.themeSkin])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("button", {
          class: _normalizeClass(["nav-link", _ctx.isTabsSuperLong?'bg-light':'']),
          role: "button",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onTabsMoveBeforeClick && _ctx.onTabsMoveBeforeClick(...args))),
          onMousedown: _cache[1] || (_cache[1] = (...args) => (_ctx.onTabsMoveBeforeStart && _ctx.onTabsMoveBeforeStart(...args))),
          onMouseup: _cache[2] || (_cache[2] = (...args) => (_ctx.onTabsMoveEnd && _ctx.onTabsMoveEnd(...args))),
          onMouseout: _cache[3] || (_cache[3] = (...args) => (_ctx.onTabsMoveEnd && _ctx.onTabsMoveEnd(...args)))
        }, [
          _createVNode(_component_svg_icon, { name: "chevron-back" })
        ], 34)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTabsList, (tab) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createVNode(_component_router_link, {
                to: {path: tab.path, query: tab.query},
                class: _normalizeClass(["nav-link border-radius-5", tab.active?'active':'']),
                "active-class": "",
                "exact-active-class": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_svg_icon, {
                    class: "nav-icon",
                    width: "1.2rem",
                    height: "1.2rem",
                    name: tab.icon
                  }, null, 8, ["name"]),
                  _createTextVNode(" " + _toDisplayString(tab.title), 1)
                ]),
                _: 2
              }, 1032, ["to", "class"])
            ]))
          }), 256))
        ], 512)
      ]),
      _createElementVNode("li", _hoisted_6, [
        _createElementVNode("button", {
          class: _normalizeClass(["nav-link", _ctx.isTabsSuperLong?'bg-light':'']),
          role: "button",
          onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.onTabsMoveBackClick && _ctx.onTabsMoveBackClick(...args))),
          onMousedown: _cache[5] || (_cache[5] = (...args) => (_ctx.onTabsMoveBackStart && _ctx.onTabsMoveBackStart(...args))),
          onMouseup: _cache[6] || (_cache[6] = (...args) => (_ctx.onTabsMoveEnd && _ctx.onTabsMoveEnd(...args))),
          onMouseout: _cache[7] || (_cache[7] = (...args) => (_ctx.onTabsMoveEnd && _ctx.onTabsMoveEnd(...args)))
        }, [
          _createVNode(_component_svg_icon, { name: "chevron-forward" })
        ], 34)
      ]),
      _createVNode(_component_app_dropdown_nav, { mode: "icon" }, {
        "dropdown-nav-view": _withCtx(() => [
          _createElementVNode("button", _hoisted_7, [
            _createVNode(_component_svg_icon, { name: "backspace-outline" })
          ])
        ]),
        "dropdown-nav-menu": _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.closes, (close) => {
            return (_openBlock(), _createElementBlock("a", {
              class: _normalizeClass(["dropdown-item", {'active': _ctx.selectedClose === close.key}]),
              onClick: $event => (_ctx.changeClose(close.key))
            }, [
              _createVNode(_component_svg_icon, {
                class: "mr-2",
                name: close.icon
              }, null, 8, ["name"]),
              _createTextVNode(" " + _toDisplayString(close.label), 1)
            ], 10, _hoisted_8))
          }), 256))
        ]),
        _: 1
      })
    ])
  ], 2))
}